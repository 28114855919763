import React, { useContext } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faSearch } from '@fortawesome/free-solid-svg-icons';

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import DrillList from "../molecules/DrillList";
import Loader from "../atoms/Loader";
import Map from "../atoms/Map";
import Copyright from "../atoms/Copyright";
import Footer from "../organisms/Footer";

const Guide = (props) => {

  const { user } = useContext(FirebaseContext);

  const guide = props.data.guide,
        maps = props.data.allMap.edges,
        areas = guide.areas;

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={guide.name} />
          <Header color={"var(--black)"}>
            <Navigator title={guide.name} />
            {guide.name === 'Montagu' && user.montaguAccess && (
              <Link to={`/${sluggify(guide.name)}/search`} className="search-icon">
                <span>Search</span>
                <FontAwesomeIcon icon={ faSearch } size={ 'lg' } />
              </Link>
            )}
          </Header>
          <main>
            <div className="container">
              <DrillList>
                <li key={guide.id} className="more" style={{backgroundColor: `var(--black)`}}>
                  <Link to={`/${sluggify(guide.name)}/about`}>About {guide.name}</Link>
                </li>
                {areas.map(area => (
                  <li key={area.id}>
                    <div className="nick" style={{backgroundColor: `${area.color}`}}>
                      <FontAwesomeIcon icon={ faCaretRight } size={ 'lg' } />
                    </div>
                    <Link to={`/${sluggify(guide.name)}/${sluggify(area.name)}`}>
                      {area.name}
                      {area.nameExtra && (
                        <sub>
                          {' - ' + area.nameExtra}
                        </sub>
                      )}
                    </Link>
                  </li>
                ))}
              </DrillList>
              
              {guide.name === 'Montagu' && user.montaguAccess && (
                <>
                  {maps && maps.sort((a, b) => a.node.order > b.node.order ? 1 : -1).map(item => (
                    <div key={item.node.id} className="map">
                      <hr />
                      <Map name={item.node.image} />
                    </div>
                  ))}
                </>
              )}

              <Copyright />
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  )
}

export const query = graphql`
  query GuideQuery($guideId: String!, $mapsArray: [String!]) {
    guide(id: {eq: $guideId}) {
      name
      areas {
        id
        name
        nameExtra
        color
      }
    }
    allMap(filter: {id: {in: $mapsArray}}) {
      edges {
        node {
          id
          image
          order
        }
      }
    }
  }
`;

export default Guide;