import React from "react";
import loadable from "@loadable/component";

const Map = ({name}) => {

  let Svg;

  switch (name) {
    case "montagu-map-1":
      Svg = loadable(() => import('../../assets/maps/montagu/montagu-map-1.inline.svg'));
      break;
    case "montagu-map-2":
      Svg = loadable(() => import('../../assets/maps/montagu/montagu-map-2.inline.svg'));
      break;
    case "bad-kloof":
      Svg = loadable(() => import('../../assets/maps/montagu/bad-kloof/bad-kloof.inline.svg'));
      break;
    case "donker-kloof":
      Svg = loadable(() => import('../../assets/maps/montagu/donker-kloof/donker-kloof.inline.svg'));
      break;
    case "gecko-rock":
      Svg = loadable(() => import('../../assets/maps/montagu/gecko-rock/gecko-rock.inline.svg'));
      break;
    default:
      Svg = null;
  }

  return (
    <>
      <Svg />
    </>
  );

};


export default Map;