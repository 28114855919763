import * as React from "react";

import './DrillList.scss';

const Drilllist = ({children}) => (
  <ul className={`drill-list`}>
    {children}
  </ul>
);

export default Drilllist;